import React, { Component } from "react";
import PropTypes from "prop-types";

import { injectIntl } from "react-intl";
import { Menu } from "primereact/menu";
import { withRouter } from "react-router-dom";

class MyMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: true,
      // isMenuLoading: true
    };
    this.wrapperMenuRef = React.createRef();
    this.handleCloseMenu = this.handleCloseMenu.bind(this);
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {}

  componentWillUnmount() {
    if (this.scrollHandler) {
      this.scrollHandler.destroy();
      this.scrollHandler = null;
    }
  }

  handleCloseMenu() {
    this.props?.handleCloseMenu();
  }

  writeSideBar() {}

  render() {
    let items = [
      {
        label: "DashBoard SAV",
        icon: "pi pi-chart-bar",
        command: () => {
          this.props.history.push("/dashboard");
        },
      },
      {
        label: "Utilisateurs",
        icon: "pi pi-users",
        command: () => {
          this.props.history.push("/profils");
        },
      },
      {
        label: "Reservation",
        icon: "pi pi-book",
        command: () => {
          this.props.history.push("/reservation");
        },
      },
      {
        label: "Likes-Matchs",
        icon: "pi pi-heart",
        command: () => {
          this.props.history.push("/likes");
        },
      },
      {
        label: "Entreprises",
        icon: "pi pi-building",
        command: () => {
          this.props.history.push("/company");
        },
      },
      {
        label: "Missions",
        icon: "pi pi-bell",
        command: () => {
          this.props.history.push("/missions");
        },
      },
      {
        label: "KPI Dashboard",
        icon: "pi pi-chart-pie",
        command: () => {
          this.props.history.push("/kpi-dashboard");
        },
      },
    ];

    return (
      <div className={this.props.className} ref={this.wrapperMenuRef}>
        {this.props.setRef(this.wrapperMenuRef)}
        <nav
          ref={(el) => {
            this.container = el;
          }}
          id={this.id}
        >
          <Menu model={items} />;
        </nav>
      </div>
    );
  }
}

MyMenu.defaultProps = {};

MyMenu.propTypes = {
  setRef: PropTypes.func.isRequired,

  intl: PropTypes.object.isRequired,
};

export default withRouter(injectIntl(MyMenu));
