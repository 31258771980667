import React from "react";
import { Badge } from "primereact/badge";

const KpiButton = ({ bgColor, data, label }) => {
  return (
    <div
      style={{
        color: "white",
        backgroundColor: bgColor,
        border: "none",
        borderRadius: 5,
        padding: 10,
        display: "flex",
        alignItems: "center",
        gap: 10,
      }}
    >
      <p style={{ fontSize: 14, fontWeight: "bold" }}>{label}</p>
      {data && (
        <Badge
          value={data}
          style={{ backgroundColor: "white", color: bgColor }}
        />
      )}
    </div>
  );
};

export default KpiButton;
