import { React, useEffect, useState } from "react";
import axios from "../../services/axios/Axios";
import "./kpidashboard.css";
import { Button } from "primereact/button";
import {
  PRIMARY_GREEN,
  PRIMARY_GREY,
  PRIMARY_RED,
  PRIMARY_YELLOW,
} from "../../styles/colors";
import KpiButton from "./KpiComponents/KpiButton";
import DotRoleColorNumber from "../Base/SharedComponentsStyled/DotRoleColorNumber";
import Idfhelpro from "../../images/idfhelpro.png";
import Provhelpro from "../../images/provhelpro.png";
import FranceHelpro from "../../images/francehelpro.png";
import DatePicker from "react-multi-date-picker";
import dayjs from "dayjs";
import { Dropdown } from "primereact/dropdown";
import KpiDataView from "./KpiComponents/KPIDataView/KpiDataView";

const KpiDashboard = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [range, setRange] = useState([
    dayjs().subtract(7, "days").toDate(),
    dayjs().toDate(),
  ]);
  const [selectedFilter, setSelectedFilter] = useState("s");
  const [kpis, setKpis] = useState({
    nbTotalProfiles: 0,
    nbTotalLiveProfiles: 0,
    nbTotalHelpros: 0,
    nbTotalHelpees: 0,
    nbTotalHelpers: 0,
    nbTotalHelpeesFrance: 0,
    nbTotalHelpersFrance: 0,
    nbTotalHelprosFrance: 0,
    nbTotalHelpeesIDF: 0,
    nbTotalHelpersIDF: 0,
    nbTotalHelprosIDF: 0,
    nbTotalHelpeesRegion: 0,
    nbTotalHelpersRegion: 0,
    nbTotalHelprosRegion: 0,
    nbTotalFranceCompletedHelpees: 0,
    nbTotalFranceCompletedHelpers: 0,
    nbTotalFranceCompletedHelpros: 0,
    nbTotalIDFCompletedHelpees: 0,
    nbTotalIDFCompletedHelpers: 0,
    nbTotalIDFCompletedHelpros: 0,
    nbTotalRegionCompletedHelpees: 0,
    nbTotalRegionCompletedHelpers: 0,
    nbTotalRegionCompletedHelpros: 0,
    nbLive: 0,
    nbLiveHelpees: 0,
    nbLiveHelpers: 0,
    nbLiveHelpros: 0,
    nbInactif1: 0,
    nbHelpeeInactif1: 0,
    nbHelperInactif1: 0,
    nbHelproInactif1: 0,
    nbInactif6: 0,
    nbHelpeeInactif6: 0,
    nbHelperInactif6: 0,
    nbHelproInactif6: 0,
    nbInactif12: 0,
    nbHelpeeInactif12: 0,
    nbHelperInactif12: 0,
    nbHelproInactif12: 0,
    nbIncomplete: 0,
    nbIncompleteHelpees: 0,
    nbIncompleteHelpers: 0,
    nbIncompleteHelpros: 0,
    nbLocked: 0,
    nbLockedHelpees: 0,
    nbLockedHelpers: 0,
    nbLockedHelpros: 0,
    nbDeleted: 0,
    nbDeletedHelpees: 0,
    nbDeletedHelpers: 0,
    nbDeletedHelpros: 0,
    nbSelfDeleted: 0,
    nbSelfDeletedHelpees: 0,
    nbSelfDeletedHelpers: 0,
    nbSelfDeletedHelpros: 0,
    nbCompleted: 0,
    nbCompletedHelpees: 0,
    nbCompletedHelpers: 0,
    nbCompletedHelpros: 0,
    nbCompletedNoSearch: 0,
    nbCompletedNoSearchHelpees: 0,
    nbCompletedNoSearchHelpers: 0,
    nbCompletedNoSearchHelpros: 0,
    nbInactiveNoSearch: 0,
    nbInactiveNoSearchHelpees: 0,
    nbInactiveNoSearchHelpers: 0,
    nbInactiveNoSearchHelpros: 0,
    nbReactivatedProfiles: 0,
    nbConnexions: 0,
    nbLikeSent: 0,
    nbLikeSentHelpee: 0,
    nbLikeSentHelpro: 0,
    nbLikeSentHelper: 0,
    nbLikeReceived: 0,
    nbLikeReceivedHelpee: 0,
    nbLikeReceivedHelpro: 0,
    nbLikeReceivedHelper: 0,
    nbUserActivity: 0,
    nbUserInactivity: 0,
    nbRevenueHelpers: 0,
    nbSubscriptions: 0,
    nbPromoCodeSubscriptions: 0,
    nbTotalConversations: 0,
    nbTotalVideoCalls: 0,
    nbTotalReservations: 0,
    nbTotalMatches: 0,
  });

  const getKpis = () => {
    setIsLoading(true);
    axios
      .get("/profiles/getKpi", {
        params: {
          startDate: dayjs(range[0]).format("YYYY-MM-DD"),
          endDate: dayjs(range[1]).format("YYYY-MM-DD"),
        },
      })
      .then((res) => {
        console.log("Response:", res);
        setKpis(res.data.kpis);
      })
      .catch((err) => {
        console.log("Error:", err);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const exportToCSV = () => {
    setIsLoading(true);

    // Prepare headers
    const headers = Object.keys(kpis).join(",");

    // Prepare values
    const values = Object.entries(kpis)
      .map(([key, value]) => {
        // Ensure churnRate or any other numeric value is formatted correctly
        if (key === "churnRate") {
          return `"'${value}"'`; // Surround the value with single quotes to treat it as plain text
        }
        return `"${value}"`;
      })
      .join(",");

    // Create CSV content
    const csvContent = `${headers}\n${values}`;

    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

    // Generate a URL for the Blob and create a link element
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "kpis.csv");

    // Append link to the document and trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    // Reset loading state
    setIsLoading(false);
  };

  const changeFilter = (value) => {
    setSelectedFilter(value);
    switch (value) {
      case "s":
        setRange([dayjs().subtract(7, "days").toDate(), dayjs().toDate()]);
        break;
      case "m":
        setRange([dayjs().subtract(1, "month").toDate(), dayjs().toDate()]);
        break;
      case "a":
        setRange([dayjs().subtract(1, "year").toDate(), dayjs().toDate()]);
        break;
      case "all":
        setRange([dayjs("2021-01-01").toDate(), dayjs().toDate()]);
        break;
      case "p":
        break;
      default:
        break;
    }
  };

  const timeSelectItems = [
    { label: "Cette semaine", value: "s" },
    { label: "Ce mois", value: "m" },
    { label: "Cette année", value: "a" },
    { label: "Toutes les données", value: "all" },
    { label: "Personnalisée", value: "p" },
  ];

  const dataViewData = {
    totals: [
      { label: "INSCRITS", value: kpis.nbTotalProfiles },
      { label: "INCOMPLETS", value: kpis.nbIncomplete },
      { label: "COMPLETS", value: kpis.nbCompleted },
      { label: "LIVES", value: kpis.nbLive },
      { label: "BLOQUÉS", value: kpis.nbLocked },
      { label: "INACTIFS 1", value: kpis.nbInactif1 },
      { label: "INACTIFS 6", value: kpis.nbInactif6 },
      { label: "INACTIFS 12", value: kpis.nbInactif12 },
      { label: "SUPPRIMÉS", value: kpis.nbDeleted },
      { label: "DÉSINSCRITS", value: kpis.nbSelfDeleted },
    ],
    helpees: [
      { label: "INSCRITS", value: kpis.nbTotalHelpees },
      { label: "INCOMPLETS", value: kpis.nbIncompleteHelpees },
      { label: "COMPLETS", value: kpis.nbCompletedHelpees },
      { label: "LIVES", value: kpis.nbLiveHelpees },
      { label: "BLOQUÉS", value: kpis.nbLockedHelpees },
      { label: "INACTIFS 1", value: kpis.nbHelpeeInactif1 },
      { label: "INACTIFS 6", value: kpis.nbHelpeeInactif6 },
      { label: "INACTIFS 12", value: kpis.nbHelpeeInactif12 },
      { label: "SUPPRIMÉS", value: kpis.nbDeletedHelpees },
      { label: "DÉSINSCRITS", value: kpis.nbSelfDeletedHelpees },
    ],
    helpers: [
      { label: "INSCRITS", value: kpis.nbTotalHelpers },
      { label: "INCOMPLETS", value: kpis.nbIncompleteHelpers },
      { label: "COMPLETS", value: kpis.nbCompletedHelpers },
      { label: "LIVES", value: kpis.nbLiveHelpers },
      { label: "BLOQUÉS", value: kpis.nbLockedHelpers },
      { label: "INACTIFS 1", value: kpis.nbHelperInactif1 },
      { label: "INACTIFS 6", value: kpis.nbHelperInactif6 },
      { label: "INACTIFS 12", value: kpis.nbHelperInactif12 },
      { label: "SUPPRIMÉS", value: kpis.nbDeletedHelpers },
      { label: "DÉSINSCRITS", value: kpis.nbSelfDeletedHelpers },
    ],
    helpros: [
      { label: "INSCRITS", value: kpis.nbTotalHelpros },
      { label: "INCOMPLETS", value: kpis.nbIncompleteHelpros },
      { label: "COMPLETS", value: kpis.nbCompletedHelpros },
      { label: "LIVES", value: kpis.nbLiveHelpros },
      { label: "BLOQUÉS", value: kpis.nbLockedHelpros },
      { label: "INACTIFS 1", value: kpis.nbHelproInactif1 },
      { label: "INACTIFS 6", value: kpis.nbHelproInactif6 },
      { label: "INACTIFS 12", value: kpis.nbHelproInactif12 },
      { label: "SUPPRIMÉS", value: kpis.nbDeletedHelpros },
      { label: "DÉSINSCRITS", value: kpis.nbSelfDeletedHelpros },
    ],
    likesSent: [
      { label: "Helpees", value: kpis.nbLikeSentHelpee },
      { label: "Helpers", value: kpis.nbLikeSentHelper },
      { label: "Helpros", value: kpis.nbLikeSentHelpro },
      { label: "Total", value: kpis.nbLikeSent },
    ],
    likesReceived: [
      { label: "Helpees", value: kpis.nbLikeReceivedHelpee },
      { label: "Helpers", value: kpis.nbLikeReceivedHelper },
      { label: "Helpros", value: kpis.nbLikeReceivedHelpro },
      { label: "Total", value: kpis.nbLikeReceived },
    ],
    nbCompletedNoSearch: [
      { label: "Helpees", value: kpis.nbCompletedNoSearchHelpees },
      { label: "Helpers", value: kpis.nbCompletedNoSearchHelpers },
      { label: "Helpros", value: kpis.nbCompletedNoSearchHelpros },
      { label: "Total", value: kpis.nbCompletedNoSearch },
    ],
    nbInactiveNoSearch: [
      { label: "Helpees", value: kpis.nbInactiveNoSearch },
      { label: "Helpees", value: kpis.nbInactiveNoSearchHelpees },
      { label: "Helpers", value: kpis.nbInactiveNoSearchHelpers },
      { label: "Helpros", value: kpis.nbInactiveNoSearchHelpros },
    ],
    nbConnexions: [{ label: "Total", value: kpis.nbConnexions }],
    nbTotalConversations: [
      { label: "Total", value: kpis.nbTotalConversations },
    ],
    nbTotalVideoCalls: [{ label: "Total", value: kpis.nbTotalVideoCalls }],
    nbTotalReservations: [{ label: "Total", value: kpis.nbTotalReservations }],
    nbTotalMatches: [{ label: "Total", value: kpis.nbTotalMatches }],
    nbReactivatedProfiles: [
      { label: "Total", value: kpis.nbReactivatedProfiles },
    ],
    nbSubscriptions: [
      { label: "Dont Code Promo", value: kpis.nbPromoCodeSubscriptions },
      { label: "Total", value: kpis.nbSubscriptions },
    ],
    nbRevenueHelpers: [{ label: "Total", value: kpis.nbRevenueHelpers }],
    nbCompanyRevenueTTC: [
      {
        label: "Total",
        value: (kpis.nbSubscriptions - kpis.nbPromoCodeSubscriptions) * 9.9,
      },
    ],
    nbCompanyRevenueHT: [
      {
        label: "Total",
        value:
          ((kpis.nbSubscriptions - kpis.nbPromoCodeSubscriptions) * 9.9) / 1.2,
      },
    ],
    nbContractsSigned: [{ label: "Total", value: "À venir" }],
    nbCompanyRevenueB2BHT: [{ label: "Total", value: "À venir" }],
  };

  return (
    <div className="kpi-dashboard-container">
      <div
        style={{
          display: "flex",
          gap: 20,
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          <Dropdown
            value={selectedFilter}
            options={timeSelectItems}
            onChange={(e) => changeFilter(e.value)}
            placeholder="Quand..."
          />
          {selectedFilter === "p" && (
            <DatePicker
              value={range}
              onChange={setRange}
              range
              style={{
                height: "45px",
                width: "200px",
                marginLeft: "20px",
              }}
              rangeHover
            />
          )}
          <div style={{ padding: "10px" }}>
            Dates: du {dayjs(range[0]).format("DD/MM/YYYY")} au{" "}
            {dayjs(range[1]).format("DD/MM/YYYY")}
          </div>
          <Button
            style={{
              color: "white",
              backgroundColor: PRIMARY_GREEN,
              border: "none",
            }}
            loading={isLoading}
            size="small"
            label="Générer les KPI"
            onClick={() => {
              getKpis();
            }}
          />
        </div>
        <Button
          style={{
            color: "white",
            backgroundColor: PRIMARY_YELLOW,
            border: "none",
          }}
          loading={isLoading}
          disabled={kpis.nbTotalProfiles === 0}
          size="small"
          label="Exporter les KPI"
          onClick={() => {
            exportToCSV();
          }}
        />
      </div>
      <div className="dashboard-kpi-section-container">
        <div
          className="dashboard-kpi-section"
          style={{ borderColor: PRIMARY_GREEN }}
        >
          <h2>INSCRIPTIONS: {kpis.nbTotalProfiles}</h2>
          <div className="kpi-dashboard-buttons-section">
            <div style={{ display: "flex", gap: 10 }}>
              <div className="kpi-btn-dtview-container">
                <KpiButton bgColor={PRIMARY_GREY} label="Nombres globaux" />
                <KpiDataView
                  bgColor={PRIMARY_GREY}
                  data={dataViewData.totals}
                  treeNodes={true}
                />
              </div>
              <div className="kpi-btn-dtview-container">
                <KpiButton bgColor={PRIMARY_RED} label="Nombre d'Helpees" />
                <KpiDataView
                  bgColor={PRIMARY_RED}
                  data={dataViewData.helpees}
                  treeNodes={true}
                />
              </div>
              <div className="kpi-btn-dtview-container">
                <KpiButton bgColor={PRIMARY_YELLOW} label="Nombre d'Helpers" />
                <KpiDataView
                  bgColor={PRIMARY_YELLOW}
                  data={dataViewData.helpers}
                  treeNodes={true}
                />
              </div>
              <div className="kpi-btn-dtview-container">
                <KpiButton bgColor={PRIMARY_GREEN} label="Nombre d'Helpros" />
                <KpiDataView
                  bgColor={PRIMARY_GREEN}
                  data={dataViewData.helpros}
                  treeNodes={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div
          name={"INSCRIT/COMPLET"}
          className="kpi-inscrits-complet-container"
        >
          <h2 style={{ marginBottom: 20 }}>RÉPARTITION GÉOGRAPHIQUE</h2>
          <div name={"INSCRIT"} className="kpi-inscrits-complet">
            <h3
              style={{
                fontSize: "14px",
                fontWeight: "900",
                color: "#999",
                float: "left",
              }}
            >
              Inscrit
            </h3>

            <div className="kpi-fr-idf-regs">
              <div>
                <div
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    src={FranceHelpro}
                    style={{ width: "32px", height: "32px" }}
                    alt="France"
                  ></img>
                  France :{" "}
                  {kpis.nbTotalHelpeesFrance +
                    kpis.nbTotalHelpersFrance +
                    kpis.nbTotalHelprosFrance}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <DotRoleColorNumber
                    role="HELPEE"
                    value={kpis.nbTotalHelpeesFrance}
                  />
                  <DotRoleColorNumber
                    role="HELPER"
                    value={kpis.nbTotalHelpersFrance}
                  />
                  <DotRoleColorNumber
                    role="HELPRO"
                    value={kpis.nbTotalHelprosFrance}
                  />
                </div>
              </div>
              <div>
                <div
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    src={Idfhelpro}
                    style={{ width: "40px", height: "32px" }}
                    alt="IDF"
                  ></img>
                  IDF :{" "}
                  {kpis.nbTotalHelpeesIDF +
                    kpis.nbTotalHelpersIDF +
                    kpis.nbTotalHelprosIDF}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <DotRoleColorNumber
                    role="HELPEE"
                    value={kpis.nbTotalHelpeesIDF}
                  />
                  <DotRoleColorNumber
                    role="HELPER"
                    value={kpis.nbTotalHelpersIDF}
                  />
                  <DotRoleColorNumber
                    role="HELPRO"
                    value={kpis.nbTotalHelprosIDF}
                  />
                </div>
              </div>
              <div>
                <div
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    src={Provhelpro}
                    style={{ width: "32px", height: "32px" }}
                    alt="Région"
                  ></img>
                  Région :{" "}
                  {kpis.nbTotalHelpeesRegion +
                    kpis.nbTotalHelpersRegion +
                    kpis.nbTotalHelprosRegion}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <DotRoleColorNumber
                    role="HELPEE"
                    value={kpis.nbTotalHelpeesRegion}
                  />
                  <DotRoleColorNumber
                    role="HELPER"
                    value={kpis.nbTotalHelpersRegion}
                  />
                  <DotRoleColorNumber
                    role="HELPRO"
                    value={kpis.nbTotalHelprosRegion}
                  />
                </div>
              </div>
            </div>
          </div>
          <div name={"COMPLETS"} className="kpi-inscrits-complet">
            <h3
              style={{
                fontSize: "14px",
                fontWeight: "900",
                color: "#999",
                float: "left",
              }}
            >
              Complets
            </h3>

            <div className="kpi-fr-idf-regs">
              <div>
                <div
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    src={FranceHelpro}
                    style={{ width: "32px", height: "32px" }}
                    alt="France"
                  ></img>
                  France :{" "}
                  {kpis.nbTotalFranceCompletedHelpees +
                    kpis.nbTotalFranceCompletedHelpers +
                    kpis.nbTotalFranceCompletedHelpros}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <DotRoleColorNumber
                    role="HELPEE"
                    value={kpis.nbTotalFranceCompletedHelpees}
                  />
                  <DotRoleColorNumber
                    role="HELPER"
                    value={kpis.nbTotalFranceCompletedHelpers}
                  />
                  <DotRoleColorNumber
                    role="HELPRO"
                    value={kpis.nbTotalFranceCompletedHelpros}
                  />
                </div>
              </div>
              <div>
                <div
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    src={Idfhelpro}
                    style={{ width: "40px", height: "32px" }}
                    alt="IDF"
                  ></img>
                  IDF :{" "}
                  {kpis.nbTotalIDFCompletedHelpees +
                    kpis.nbTotalIDFCompletedHelpers +
                    kpis.nbTotalIDFCompletedHelpros}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <DotRoleColorNumber
                    role="HELPEE"
                    value={kpis.nbTotalIDFCompletedHelpees}
                  />
                  <DotRoleColorNumber
                    role="HELPER"
                    value={kpis.nbTotalIDFCompletedHelpers}
                  />
                  <DotRoleColorNumber
                    role="HELPRO"
                    value={kpis.nbTotalIDFCompletedHelpros}
                  />
                </div>
              </div>
              <div>
                <div
                  style={{
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <img
                    src={Provhelpro}
                    style={{ width: "32px", height: "32px" }}
                    alt="Région"
                  ></img>
                  Région :{" "}
                  {kpis.nbTotalRegionCompletedHelpees +
                    kpis.nbTotalRegionCompletedHelpers +
                    kpis.nbTotalRegionCompletedHelpros}
                </div>
                <div
                  style={{
                    marginTop: "10px",
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <DotRoleColorNumber
                    role="HELPEE"
                    value={kpis.nbTotalRegionCompletedHelpees}
                  />
                  <DotRoleColorNumber
                    role="HELPER"
                    value={kpis.nbTotalRegionCompletedHelpers}
                  />
                  <DotRoleColorNumber
                    role="HELPRO"
                    value={kpis.nbTotalRegionCompletedHelpros}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className="dashboard-kpi-section"
          style={{ borderColor: PRIMARY_RED }}
        >
          <h2>ACTIVITÉ IN APP</h2>
          <div className="kpi-dashboard-buttons-section">
            <div style={{ display: "flex", gap: 11 }}>
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Nombre de likes envoyés"
                />
                <KpiDataView data={dataViewData.likesSent} />
              </div>
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Nombre de likes reçus"
                />
                <KpiDataView data={dataViewData.likesReceived} />
              </div>
            </div>
            <div style={{ display: "flex", gap: 10 }}>
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Cmpts 0 résultats de recherche"
                />
                <KpiDataView data={dataViewData.nbCompletedNoSearch} />
              </div>
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Incts 0 résultats de recherche"
                />
                <KpiDataView data={dataViewData.nbInactiveNoSearch} />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "space-between",
              }}
            >
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Nombre de connexions à l'app"
                />
                <KpiDataView data={dataViewData.nbConnexions} />
              </div>
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Nombre de discussions chat"
                />
                <KpiDataView
                  bgColor={PRIMARY_GREY}
                  data={dataViewData.nbTotalConversations}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "space-between",
              }}
            >
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Nombre de calls vidéos"
                />
                <KpiDataView
                  bgColor={PRIMARY_GREY}
                  data={dataViewData.nbTotalVideoCalls}
                />
              </div>
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Nombre de réservations"
                />
                <KpiDataView
                  bgColor={PRIMARY_GREY}
                  data={dataViewData.nbTotalReservations}
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: 10,
                justifyContent: "space-between",
              }}
            >
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Nombre de mises en relation"
                />
                <KpiDataView
                  bgColor={PRIMARY_GREY}
                  data={dataViewData.nbTotalMatches}
                />
              </div>
              <div className="kpi-btn-dtview-container">
                <KpiButton
                  bgColor={PRIMARY_GREY}
                  label="Nombre de profils réactivés"
                />
                <KpiDataView
                  bgColor={PRIMARY_GREY}
                  data={dataViewData.nbReactivatedProfiles}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="dashboard-kpi-section"
        style={{ borderColor: PRIMARY_YELLOW }}
      >
        <h2>BUSINESS</h2>
        <div className="kpi-dashboard-buttons-section">
          <div className="kpi-btn-dtview-container">
            <KpiButton bgColor={PRIMARY_GREY} label="Revenu Helpers" />
            <KpiDataView
              bgColor={PRIMARY_GREY}
              data={dataViewData.nbRevenueHelpers}
            />
          </div>
          <div className="kpi-btn-dtview-container">
            <KpiButton bgColor={PRIMARY_GREY} label="Nombre d'abonnements" />
            <KpiDataView
              bgColor={PRIMARY_GREY}
              data={dataViewData.nbSubscriptions}
            />
          </div>
          <div className="kpi-btn-dtview-container">
            <KpiButton bgColor={PRIMARY_GREY} label="CA B2C TTC" />
            <KpiDataView
              bgColor={PRIMARY_GREY}
              data={dataViewData.nbCompanyRevenueTTC}
            />
          </div>
          <div className="kpi-btn-dtview-container">
            <KpiButton bgColor={PRIMARY_GREY} label="CA B2C HT" />
            <KpiDataView
              bgColor={PRIMARY_GREY}
              data={dataViewData.nbCompanyRevenueHT}
            />
          </div>
          <div className="kpi-btn-dtview-container">
            <KpiButton bgColor={PRIMARY_GREY} label="Contrats signés" />
            <KpiDataView
              bgColor={PRIMARY_GREY}
              data={dataViewData.nbContractsSigned}
            />
          </div>
          <div className="kpi-btn-dtview-container">
            <KpiButton bgColor={PRIMARY_GREY} label="CA B2B engagé HT" />
            <KpiDataView
              bgColor={PRIMARY_GREY}
              data={dataViewData.nbCompanyRevenueB2BHT}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default KpiDashboard;
